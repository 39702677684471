import React from "react";
import InfoCard from "./info-card";
import "./info-card-wrapper.css";

const InfoCardWrapper = ({ data = [] }) => {
  return (
    <div className="info-card-wrapper">
      {data.map((item, index) => (
        <InfoCard
          key={index}
          title={item.title}
          subtitle={item.subtitle}
          isPercent={item.isPercent || false}
          isLike={item.isLike || false}
          isDislike={item.isDislike || false}
        />
      ))}
    </div>
  );
};

export default InfoCardWrapper;