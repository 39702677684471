import Answer from "./answer";
import Question from "./question";
import { useState } from "react";
import AnswerDrawer from "./answer-drawer";
import { InputNumber, Tabs } from "antd";

const Picker = ({ data, setAnswer, answeredLabel, inProcess }) => {
  const [isOpen, setIsOpen] = useState();
  const [value, setValue] = useState();
  const [height, setHeight] = useState();
  const [tab, setTab] = useState(1);

  const updateHeight = (name, num) => {
    if (num === null) {
      setHeight();
      return;
    }

    setHeight({ ...height, [name]: num });
  };

  const updateAnswers = (item) => {
    if (item === null) {
      setValue();
      return;
    }

    const int = +item;

    if (data.step) {
      return int % data.step
        ? setValue(
            (Math.round(int / data.step) * data.step).toFixed(
              data.decimal ? 1 : 0
            )
          )
        : setValue(+int.toFixed(data.decimal ? 1 : 0));
    }

    setValue(+int.toFixed(data.decimal ? 1 : 0));
  };

  const handleSubmit = () => {
    if (!value && !height) return;
    if (data.type === "height") {
      const highMetrics = tab === 1 ? "m" : "ft";
      const lowMetrics = tab === 1 ? "cm" : "inch";
      setAnswer({
        answer: `${height[highMetrics]} ${highMetrics} ${height[lowMetrics]} ${lowMetrics}`,
      });
    } else if (data.type === "width" || data.type === "weight") {
      setAnswer(`${value} ${tab === 1 ? "kg" : "lbs"}`);
    } else {
      setAnswer(`${value} ${data.suffix ?? ""}`);
    }
    setIsOpen(false);
    setValue();
  };

  const onTabChange = (key) => {
    setTab(key);
  };

  const drawerHeight = () => {
    switch (data.type) {
      case "height":
        return 380;
      case "width":
        return 320;
      case "weight":
        return 320;
      default:
        return 240;
    }
  };

  const tabsMeterList = [
    {
      key: "1",
      label: "M/CM",
      children: (
        <div className="multiple-choice-answers">
          <InputNumber
            style={{ width: "100%", marginBottom: 12 }}
            value={value}
            placeholder={data.placeholder}
            size="large"
            addonAfter={"m"}
            min={data.min}
            max={data.max}
            step={data.step}
            decimalSeparator="."
            onChange={(v) => updateHeight("m", v)}
            controls
            stringMode={data.decimal}
          />
          <InputNumber
            style={{ width: "100%" }}
            value={value}
            placeholder={data.placeholder}
            size="large"
            addonAfter={"cm"}
            min={data.min}
            max={data.max}
            step={data.step}
            decimalSeparator="."
            onChange={(v) => updateHeight("cm", v)}
            controls
            stringMode={data.decimal}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "FT/INCH",
      children: (
        <div className="multiple-choice-answers">
          <InputNumber
            style={{ width: "100%", marginBottom: 12 }}
            value={value}
            placeholder={data.placeholder}
            size="large"
            addonAfter={"ft"}
            min={data.min}
            max={data.max}
            step={data.step}
            decimalSeparator="."
            onChange={(v) => updateHeight("ft", v)}
            controls
            stringMode={data.decimal}
          />
          <InputNumber
            style={{ width: "100%" }}
            value={value}
            placeholder={data.placeholder}
            size="large"
            addonAfter={"inch"}
            min={data.min}
            max={data.max}
            step={data.step}
            decimalSeparator="."
            onChange={(v) => updateHeight("inch", v)}
            controls
            stringMode={data.decimal}
          />
        </div>
      ),
    },
  ];

  const tabsWeightList = [
    {
      key: "1",
      label: "KG",
      children: (
        <div className="multiple-choice-answers">
          <InputNumber
            style={{ width: "100%" }}
            value={value}
            placeholder={data.placeholder}
            size="large"
            addonAfter={"kg"}
            min={data.min}
            max={data.max}
            step={data.step}
            decimalSeparator="."
            onChange={updateAnswers}
            controls
            stringMode={data.decimal}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "LBS",
      children: (
        <div className="multiple-choice-answers">
          <InputNumber
            style={{ width: "100%" }}
            value={value}
            placeholder={data.placeholder}
            size="large"
            addonAfter={"lbs"}
            min={data.min}
            max={data.max}
            step={data.step}
            decimalSeparator="."
            onChange={updateAnswers}
            controls
            stringMode={data.decimal}
          />
        </div>
      ),
    },
  ];

  const renderPicker = () => {
    switch (data.type) {
      case "height":
        return (
          <Tabs
            defaultActiveKey="1"
            items={tabsMeterList}
            onChange={onTabChange}
            centered
          />
        );

      case "width":
        return (
          <Tabs
            defaultActiveKey="1"
            items={tabsWeightList}
            onChange={onTabChange}
            centered
          />
        );

      case "weight":
        return (
          <Tabs
            defaultActiveKey="1"
            items={tabsWeightList}
            onChange={onTabChange}
            centered
          />
        );

      default:
        return (
          <div className="multiple-choice-answers">
            <InputNumber
              style={{ width: "100%" }}
              value={value}
              placeholder={data.placeholder}
              size="large"
              addonAfter={data.suffix}
              min={data.min}
              max={data.max}
              step={data.step}
              decimalSeparator="."
              onChange={updateAnswers}
              controls
              stringMode={data.decimal}
            />
          </div>
        );
    }
  };

  return (
    <div className="multiple-choice">
      <Question text={data.message} />
      <div className="multiple-choice-answers">
        {answeredLabel ? (
          <Answer
            key={`Its answer ${Math.random()}`}
            text={answeredLabel}
            selected={answeredLabel}
            onClick={() => setAnswer()}
          />
        ) : (
          <Answer
            key={`Select answers${Math.random()}`}
            text={data.label === "Picker" ? "Reply…" : data.label ?? "Reply…"}
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>

      <AnswerDrawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={data.message}
        submit={handleSubmit}
        height={drawerHeight()}
        isValid={!!value}
        destroyOnClose={true}
      >
        {renderPicker()}
      </AnswerDrawer>
    </div>
  );
};

export default Picker;
