import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const getTasks = (query) => axios.get(toQueryString("api/v1/task-managements?", query));

const addTask = (task) => axios.post("api/v1/task-managements", task);

const updateTask = (id, task) =>
  axios.put(`api/v1/task-managements/${id}`, task);

const deleteTask = (id) => axios.delete(`api/v1/task-managements/${id}`);

const ApiTaskManagement = {
  getTasks,
  addTask,
  updateTask,
  deleteTask,
};

export default ApiTaskManagement;
