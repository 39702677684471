import Answer from "./answer";
import Question from "./question";
import { useState, useEffect } from "react";
import AnswerDrawer from "./answer-drawer";
import useElseValue, { isItemElse } from "../../hooks/useElseValue";

const MultipleChoice = ({ data, setAnswer, answeredLabel, elseResponse, inProcess }) => {
  const [isOpen, setIsOpen] = useState();
  const [answers, setAnswers] = useState([]);
  const {
    elseSelectedItem,
    elseValue,
    handleSetAnswer: handleElseSetAnswer,
    renderElseBlock,
  } = useElseValue(setAnswer, answeredLabel, false);

  const updateAnswers = (item) => {
    if (answers.includes(item)) {
      setAnswers(answers.filter((a) => a !== item));
    } else {
      setAnswers([...answers, item]);
    }
  };

  const handleSetAnswer = (item) => {
    if (inProcess) return;

    if (isItemElse(item)) {
      handleElseSetAnswer(item);
      updateAnswers(item);
      return;
    }

    if (!answeredLabel) {
      updateAnswers(item);
      return;
    }

    setAnswer();
  };

  const handleSubmit = () => {
    if (!answers.length) return;
    const answersWithElse = answers.reduce((acc, item, idx) => {
      if (isItemElse(item)) {
        acc[idx] = {
          ...item,
          elseValue,
        };
      } else {
        acc[idx] = item;
      }
      return acc;
    }, []);
    setAnswer({ answers: answersWithElse });
    setAnswers([]);
    setIsOpen(false);
  };

  useEffect(() => {
    setAnswers([]);
  }, [isOpen]);

  return (
    <div className="multiple-choice">
      <Question text={data.message} />
      <div className="multiple-choice-answers">
        {answeredLabel ? (
          data.response.map((item) => (
            <Answer
              key={item.label}
              text={isItemElse(item) ? 'Other' : item.label}
              locked={answeredLabel && answeredLabel.map(item => item.label).includes(item.label)}
              selected={answeredLabel && answeredLabel.map(item => item.label).includes(item.label)}
              onClick={() => handleSetAnswer(item)}
            />
          ))
        ) : (
          <Answer
            key={`Select answers${Math.random()}`}
            text={data.label ?? "Enter response..."}
            onClick={() => setIsOpen(true)}
          />
        )}
        {renderElseBlock(elseResponse, true)}
      </div>

      <AnswerDrawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={data.message}
        submit={handleSubmit}
        height={(elseSelectedItem ? 280 : 210) + data.response.length * 48}
      >
        <div className="multiple-choice-answers">
          {data.response.map((item) => (
            <Answer
              key={item.label}
              text={item.label?.startsWith('<>') ? 'Other' : item.label}
              locked={answeredLabel && item.label !== answeredLabel}
              selected={answers.includes(item) || elseSelectedItem?.label === item.label}
              onClick={() => handleSetAnswer(item)}
            />
          ))}
          {renderElseBlock(elseResponse)}
        </div>
      </AnswerDrawer>
    </div>
  );
};

export default MultipleChoice;
