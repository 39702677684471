import { MainLayoutV2 } from "../../components/main-layout-v2/main-layout-v2";
import { MainNavigation } from "../../components/main-navigation/main-navigation";
import { Sidebar } from "../../components/sidebar/sidebar";
import { memo } from "react";
import { Outlet, useLocation } from "react-router-dom";

export const Administration = memo(() => {
  const { pathname } = useLocation();

  const navigation = [
    {
      label: "Content management",
      type: "group",
      children: [
        {
          key: "/administration/tasks",
          label: "Tasks",
          default: pathname === "/administration" || pathname === "/administration/tasks"
        },
        {
          key: "/administration/plans",
          label: "Plans",
        },
        {
          key: "/administration/lifepoints",
          label: "LifePoints",
        },
        {
          key: "/administration/lifebot",
          label: "LifeBot",
        },
        {
          key: "/administration/categories",
          label: "Categories",
        },
        {
          key: "/administration/cohorts",
          label: "Cohorts",
        },
        {
          key: "/administration/articles",
          label: "Articles",
        },
        {
          key: "/administration/fitfacts",
          label: "FitFacts",
        },
        {
          key: "/administration/scenarios",
          label: "Scenarios",
        },
        {
          key: "/administration/onboardings",
          label: "Onboarding",
        }
      ]
    },
    {
      label: "User management",
      type: "group",
      children: [
        { key: "/administration/system-users", label: "System users" },
        // { key: "/administration/disabled-users", label: "Blocked users" },
        // { key: "/administration/pending-users", label: "Pending users" },
      ]
    }
  ];

  return (
    <MainLayoutV2>
      <MainNavigation />
      <Sidebar items={navigation} title={"Administration"} />
      <Outlet />
    </MainLayoutV2>
  );
});
