import { useTranslation } from "react-i18next";
import { Progress } from "antd";
import TableСomponent from "../../../components/table/table";
import { BlockComponent, BlockHeader } from "../../../components/block/block";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

const AdherenceTable = ({ subjects, fetchSubjects }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toSubjectPage = (subject) => {
    navigate(`/subject-management/subject/${subject.id}#profile`);
  };

  // const [sorter, setSorter] = useState({ field: null, order: null });

  const handleTableChange = (pagination, filters, sorter) => {
    fetchSubjects({
      sortField: sorter.field,
      sortOrder: sorter.order === "ascend" ? "asc" : "desc",
    });
  };

  const adherence = [
    {
      title: t("subject_management.table.subjectId"),
      dataIndex: "subjects",
      sorter: true,
      render: (_, user) => (
        <span style={{ fontWeight: 700 }}>{user.displayId || "N/A"}</span>
      ),
      key: "id",
      width: "15%",
    },
    {
      title: t("subject_management.table.cohort"),
      dataIndex: "cohort",
      sorter: true,
      render: (_, user) => <span>{user.cohort || "N/A"}</span>,
      key: "cohort",
      width: "15%",
    },
    {
      title: t("subject_management.table.point_per_week"),
      dataIndex: "pointPerWeek",
      sorter: true,
      render: (_, user) => <span>{user.point_per_week || "N/A"}</span>,
      key: "point_per_week",
      width: "14%",
    },
    {
      title: t("subject_management.table.total_points"),
      dataIndex: "points",
      sorter: true,
      render: (_, user) => <span>{user.total_points || "N/A"}</span>,
      key: "points",
      width: "14%",
      // align: "right",
    },
    {
      title: t("subject_management.table.current_streak"),
      dataIndex: "currentStreak",
      sorter: true,
      render: (_, user) => <span>{user.current_streak || "N/A"}</span>,
      key: "current_streak",
      width: "14%",
      // align: "right",
    },
    {
      title: t("subject_management.table.longest_streak"),
      dataIndex: "longestStreak",
      sorter: true,
      render: (_, user) => <span>{user.longest_streak || "N/A"}</span>,
      key: "longest_streak",
      width: "14%",
      // align: "right",
    },
    {
      title: t("subject_management.table.adherence"),
      dataIndex: "adherence",
      sorter: true,
      render: (_, user) => (
        // <Progress size="small" percent={Math.floor(user.adherence * 100)} />
        <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Progress
            type="circle"
            percent={Math.floor(user.adherence * 100) || 0}
            width={25}
            strokeLinecap="butt"
            strokeWidth={25}
            strokeColor="#2D7FFA"
            trailColor="rgba(45, 127, 250, 0.2)"
            format={() => ""}
          />
          {Math.floor(user.adherence * 100) || 0}%
        </span>
      ),
      key: "adherence",
      width: "15%",
    }
  ];

  const adherenceData = useMemo(
    () => [
      ...subjects.map((s) => {
        return {
          id: s.id,
          displayId: s.displayId,
          cohort: s.cohort,
          adherence: s.adherence || 0,
          total_points: s.totalScore,
          point_per_week: Math.round(s.averageWeekPoints),
          longest_streak: s.longestStreak,
          current_streak: s.currentStreak
        };
      }),
    ],
    [subjects]
  );

  return (
    <BlockComponent>
      <BlockHeader title={"Subjects stats"} size={"md"} />
      <TableСomponent
        columns={adherence}
        data={adherenceData}
        onHandleRowClick={toSubjectPage}
        onChange={handleTableChange}
      />
    </BlockComponent>
  );
};

export default AdherenceTable;
