import React from "react";
import Answer from "./answer";
import Question from "./question";
import useElseValue, {isItemElse} from "../../hooks/useElseValue";

const SingleChoice = ({ data, setAnswer, answeredLabel, elseResponse }) => {
  const {
    elseSelectedItem,
    handleSetAnswer,
    renderElseBlock,
  } = useElseValue(setAnswer, answeredLabel);

  return (
    <div className="multiple-choice">
      <Question text={data.message} />
      <div className="multiple-choice-answers">
        {data.response.map((item) => (
          <Answer
            key={item.label}
            text={isItemElse(item) ? 'Other' : item.label}
            locked={answeredLabel && item.label !== answeredLabel}
            selected={(answeredLabel && item.label === answeredLabel) || elseSelectedItem?.label === item.label}
            onClick={() => handleSetAnswer(item)}
          />
        ))}
      </div>
      {renderElseBlock(elseResponse)}
    </div>
  );
};

export default SingleChoice;
