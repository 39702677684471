import { useState, useEffect } from 'react';
import { Input, Button } from 'antd';

export const isItemElse = (item) => item?.label.startsWith('<>');

const useElseValue = (setAnswer, answeredLabel, showSubmitButton = true) => {
  const [elseSelectedItem, setElseSelectedItem] = useState(null);
  const [elseValue, setElseValue] = useState(null);

  useEffect(() => {
    return () => {
      setElseSelectedItem(null);
    };
  }, []);

  useEffect(() => {
    if (answeredLabel) {
      setElseValue(null);
    }
  }, [answeredLabel]);

  const handleSetAnswer = (item) => {
    if (isItemElse(item) && answeredLabel) {
      setAnswer(null);
      setElseSelectedItem(null);
      return;
    }

    if (isItemElse(item)) {
      // Toggle elseSelectedItem - set to null if it's already the selected item
      setElseSelectedItem(prev => prev?.label === item.label ? null : item);
      return;
    }

    setElseSelectedItem(null);
    setAnswer(item);
  };

  const renderElseBlock = (elseValueSaved, forceDisabled = false) => {
    if (!elseSelectedItem && !elseValueSaved) return null;

    return (
      <div className="else-block">
        <div className="input-container">
          <Input
            placeholder="Please provide response"
            bordered={false}
            value={(elseValue || elseValueSaved) ?? ''}
            className="else-block__input"
            disabled={(answeredLabel && elseSelectedItem.label === answeredLabel) || forceDisabled}
            onChange={(e) => setElseValue(e.target.value)}
          />
        </div>
        {!answeredLabel && showSubmitButton ? (
          <div className="else-block-submit">
            <Button
              type="primary"
              className="else-block-submit__button multiple-choice-submit-btn"
              onClick={() => setAnswer({
                ...elseSelectedItem,
                elseValue,
              })}
            >
              Submit
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  return {
    elseSelectedItem,
    elseValue,
    handleSetAnswer,
    renderElseBlock,
  };
};

export default useElseValue;
