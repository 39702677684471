import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTrails,
  fetchTrails,
  selecthasMoreTrails,
  selectTrails,
  selectTrailsLoading,
} from "../../../../store/slices/trails";
import AuditTrailHeader from "../audit-trail-header";
import "../../styles.css";
import InfinityTable from "../../../../components/infinity-table/infinity-table";
import ApiTrails from "../../../../api/trail";
import moment from "moment";
import { Body } from "../../../../components/main-body/body";
import classNames from "classnames";
import "../../../../components/main-body/body.css";

const UsersTrailTable = ({ navigation }) => {
  const trails = useSelector(selectTrails);
  const hasMore = useSelector(selecthasMoreTrails);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  // const [isLoading, setLoading] = useState(false);
  const isLoading = useSelector(selectTrailsLoading);
  const [filters, setFilters] = useState({});

  const fetchData = (currentPage = page) => {
    if (!isLoading) {
      setPage(currentPage + 1);
      dispatch(
        fetchTrails({
          page: currentPage,
          ...filters,
          createdAtEnd: filters.period?.[filters.period.length - 1]?.end,
          createdAtStart: filters.period?.[filters.period.length - 1]?.start,
        })
      );
    }
  };

  const updateFilterValue = (filter) => {
    setFilters(filter);
  };

  // useEffect(() => {
  //   if (trails && trails.length) {
  //     setLoading(false);
  //   }
  // }, [trails]);

  useEffect(() => {
    dispatch(clearTrails());
    fetchData(1);
  }, [filters, dispatch]);

  const columns = [
    {
      title: t("trails.table.id"),
      dataIndex: "id",
      render: (_, trail) => <span>{trail.id || "N/A"}</span>,
      key: "id",
      width: "5%",
    },
    {
      title: t("trails.table.time"),
      dataIndex: "time",
      key: "time",
      render: (_, trail) => (
        <span>
          {`${moment(trail.createdAt)
            .utc()
            .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
        </span>
      ),
      width: "10%",
    },
    {
      title: t("trails.table.email"),
      dataIndex: "email",
      key: "email",
      render: (_, trail) => <span>{trail.userEmail || "N/A"}</span>,
      width: "15%",
    },
    {
      title: t("trails.table.full_name"),
      dataIndex: "full_name",
      key: "full_name",
      render: (_, trail) => <span>{trail.userName || "N/A"}</span>,
      width: "15%",
    },
    {
      title: t("trails.table.updated_from"),
      dataIndex: "updated_from",
      key: "updated_from",
      render: (_, trail) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {trail.valueFrom || "N/A"}
        </span>
      ),
      width: "15%",
    },
    {
      title: t("trails.table.updated_to"),
      dataIndex: "updated_to",
      key: "updated_to",
      render: (_, trail) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {trail.valueTo || "N/A"}
        </span>
      ),
      width: "15%",
    },
    {
      title: t("trails.table.action"),
      dataIndex: "action",
      key: "action",
      render: (_, trail) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {trail.action || "N/A"}
        </span>
      ),
      width: "25%",
    },
  ];

  const actionTypes = [
    { id: "unsuccessfulLoginAttempt", name: "Unsuccessful login attempt" },
    { id: "userBlocked", name: "Blocked after unsuccessful login attempt" },
    { id: "userLogged", name: "Logged to the system using native Login" },
    { id: "userInvited", name: "Invited user" },
    { id: "createdEnrollmentField", name: "Created enrollmentField" },
    { id: "updatedEnrollmentField", name: "Updated enrollmentField" },
    {
      id: "updatedEnrollmentFieldOption",
      name: "Updated enrollmentFieldOption",
    },
    { id: "createEnrolmentData", name: "Create enrolment data" },
    { id: "updatedEnrolmentData", name: "Updated enrolment data" },
    { id: "deletedEnrolmentData", name: "Deleted enrolment data" },
    { id: "createdSetting", name: "Created setting" },
    { id: "updatedSetting", name: "Updated setting" },
    {
      id: "updatedSubjectAvatar",
      name: "Updated avatar",
    },
    { id: "updatedSubjectStatus", name: "Updated status for subject" },
    { id: "subjectDeactivated", name: "Subject deactivated" },
    { id: "subjectDeleted", name: "Subject deleted" },
    { id: "subjectActivated", name: "Subject activated" },
    { id: "invitedSubject", name: "Invited subject" },
    { id: "reinvitedSubject", name: "Re-invited subject" },
    { id: "updatedAvatar", name: "Updated avatar" },
    { id: "updatedRole", name: "Updated role" },
    { id: "updatedStatus", name: "Updated user status" },
    { id: "updatedFirstName", name: "Updated first name for user" },
    { id: "updatedLastName", name: "Updated last name for user" },
    { id: "activatedAccount", name: "Activated user account" },
    {
      id: "completedResetPassword",
      name: "Completed reset password procedure",
    },
    { id: "changedPassword", name: "Сhanged password" },
    { id: "userDeactivated", name: "User deactivated" },
    { id: "userDeleted", name: "User deleted" },
    { id: "userActivated", name: "User activated" },
  ];

  const filterItems = [
    // { name: "Author", id: "userId", children: authors },
    { name: "Date peroid", id: "period", period: true },
    { name: "Action type", id: "actionType", children: actionTypes },
  ];

  const exportTrail = () => {
    ApiTrails.exportTrails();
  };

  return (
    <Body disabledPaddings={true}>
      <AuditTrailHeader
        t={t}
        filterItems={filterItems}
        updateFilterValue={updateFilterValue}
        exportTrail={exportTrail}
      />
      <div className={classNames("lf-content")}>
        <InfinityTable
          data={trails}
          fetchData={fetchData}
          hasMore={hasMore}
          tableColumns={columns}
          isLoading={isLoading}
          before={navigation}
        />
      </div>
    </Body>
  );
};

export default UsersTrailTable;
