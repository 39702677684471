import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIsOpenProfile,
  setProfileUser,
} from "../../../store/slices/user_management";
import "./styles.css";
import getDateByDiff from "../../../common/utils/calendar/get-date-by-diff";
import UserActivityStatus from "../../../components/user-activity-status/user-activity-status";
import InfinityTable from "../../../components/infinity-table/infinity-table";
import useSearch from "./useSearch";
import useUsers from "./useUsers";
import InviteUserModal from "../../../components/modals/invitations/user/invite-user";
import { Body } from "../../../components/main-body/body";
import { TableCell } from "../../../components/table/table-cell/table-cell";
import { TableCellChips } from "../../../components/table/table-cell/table-cell-chips";
import classNames from "classnames";
import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";

const roles = {
  admin: "Administrator",
  user: "User",
};

// const roleColors = {
//   Administrator: "#2762f5",
//   User: "rgba(255, 171, 0, 1)",
// };

const UserManagament = ({ status, type }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { search, handleUpdateSearchValue } = useSearch();
  const { users, hasMore, isLoading, fetchUsers } = useUsers(search);
  const [isOpen, setOpen] = useState(false);
  const { id } = useParams();
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const handleToogleModal = () => {
    if (isOpen) {
      fetchUsers();
    }
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (id && users?.length) {
      const user = users.find((user) => user.id === id);
      if (user) {
        dispatch(setProfileUser(user));
        dispatch(setIsOpenProfile(true));
        // handleNavigate(`/administration/onboardings/${user.id}`);
      }
    }
  }, [id, users]);

  useEffect(() => {
    if (!id) {
      dispatch(setIsOpenProfile(false));
      dispatch(setProfileUser(null));
    }
  }, [id, dispatch]);

  useEffect(() => {
    console.log('USE EFFECT FETCH filters', filters)
    fetchUsers({ search, ...filters });
  }, [filters]);

  const { t } = useTranslation();

  const handleNavigate = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const openUserProfile = (user) => {
    dispatch(setProfileUser(user));
    dispatch(setIsOpenProfile(true));
    handleNavigate(`${pathname}/${user.id}`);
  };

  const headerTitle = useMemo(() => {
    switch (pathname) {
      case "/administration/disabled-users":
        return t("user_management.title_disabled");
      case "/administration/pending-users":
        return t("user_management.title_pending");
      default:
        return t("user_management.title");
    }
  }, [type, t]);

  const tableColumns = [
    {
      title: t("user_management.table.name"),
      dataIndex: "name",
      render: (_, user) => (
        <TableCell
          img={user.avatar || " "}
          title={`${user.firstName} ${user.lastName}`}
        />
      ),
      key: "name",
      width: "30%",
    },
    {
      title: t("user_management.table.email"),
      dataIndex: "email",
      key: "email",
      // render: (_, user) => <span>{user.email || "N/A"}</span>,
      render: (_, user) => <TableCell text={user.email || "N/A"} />,
      width: "30%",
    },
    {
      title: t("user_management.table.role"),
      dataIndex: "role",
      key: "role",
      render: (_, user) => (
        <TableCellChips
          color={user.role === "admin" ? "violet" : "grey"}
          text={roles[user.role]}
        />
      ),
    },
    {
      title: t("user_management.table.last_active"),
      dataIndex: "last_active",
      key: "last_active",
      render: (_, user) => (
        <TableCell text={getDateByDiff(user.lastActivity)} />
      ),
    },
    {
      title: t("user_management.table.status"),
      dataIndex: "status",
      key: "status",
      render: (_, user) => (
        <div className={classNames("lf-table-cell")}>
          <UserActivityStatus userStatus={user.status} />
        </div>
      ),
    },
  ];

  const headerProps = {
    title: headerTitle,
    search: { value: search, onHandleChange: handleUpdateSearchValue },
    create: {
      label: t("user_management.btn.invite"),
      onClick: handleToogleModal,
      renderModalComponent: (
        <InviteUserModal isOpen={isOpen} close={handleToogleModal} />
      ),
    },
  };

  const updateFilterValue = (value) => setFilters(value);
  const rolesFilter = [{ id: 'admin', name: 'Administrator'}, { id: 'user', name: 'User'}]
  // created - before invitation sending
  // invited
  // inviteError
  // confirmed - active user
  // blocked - block after 5 failed attempts
  // inactive 
  const statuses = [{ id: 'confirmed', name: 'Active'}, { id: 'invited', name: 'Invited'}, { id: 'inviteError', name: 'Invitation error'}, { id: 'blocked', name: 'Blocked'}, { id: 'inactive', name: 'Inactive'}]
  return (
    <Body header={headerProps}
      filters={
        <DropdownFilters
        filtersBy={[
          { name: "Role", id: "roles", children: rolesFilter },
          { name: "Status", id: "status", children: statuses }
        ]}
        updateFilterValue={updateFilterValue}
        />
      }
    >
      <InfinityTable
        data={users}
        fetchData={fetchUsers}
        hasMore={hasMore}
        tableColumns={tableColumns}
        isLoading={isLoading}
        onHandleRowClick={openUserProfile}
      />
    </Body>
  );
};

export default UserManagament;
