import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSubjectTrails,
  fetchSubjectTrails,
  selecthasMoreSubjectTrails,
  selectSubjectTrails,
  selectSubjectTrailsLoading,
} from "../../../../store/slices/subject-trails";
import AuditTrailHeader from "../audit-trail-header";
import "../../styles.css";
import InfinityTable from "../../../../components/infinity-table/infinity-table";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import ApiTrails from "../../../../api/trail";
import moment from "moment";
import { Body } from "../../../../components/main-body/body";
import "../../../../components/main-body/body.css";
import classNames from "classnames";

const SubjectsTrailTable = () => {
  const trails = useSelector(selectSubjectTrails);
  const hasMore = useSelector(selecthasMoreSubjectTrails);
  const loading = useSelector(selectSubjectTrailsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [subjectsList, setSubjectsList] = useState([]);

  const fetchSubjectsList = () => {
    ApiSubjectsManagement.getSubjects().then((r) => {
      setSubjectsList(
        r.data.subjects.map((s) => ({
          id: s.displayId,
          name: s.displayId,
        }))
      );
    });
  };

  useEffect(() => {
    fetchSubjectsList();
  }, []);

  const fetchData = (currentPage = page) => {
    if (!loading) {
      setPage(currentPage + 1);
      dispatch(
        fetchSubjectTrails({
          page: currentPage,
          ...filters,
          createdAtEnd: filters.period?.[filters.period.length - 1]?.end,
          createdAtStart: filters.period?.[filters.period.length - 1]?.start,
        })
      );
    }
  };

  const updateFilterValue = (filter) => {
    setFilters(filter);
  };

  useEffect(() => {
    if (trails) {
      dispatch(clearSubjectTrails());
    }
    fetchData(1);
  }, [filters]);

  const columns = [
    {
      title: t("trails.table.id"),
      dataIndex: "id",
      render: (_, trail) => <span>{trail.subjectId || "N/A"}</span>,
      key: "id",
    },
    {
      title: t("trails.table.time"),
      dataIndex: "time",
      key: "time",
      render: (_, trail) => (
        <span>
          {`${moment(trail.createdAt)
            .utc()
            .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
        </span>
      ),
    },
    {
      title: t("trails.table.action"),
      dataIndex: "action",
      key: "action",
      render: (_, trail) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {trail.action || "N/A"}
        </span>
      ),
    },
  ];

  const actionTypes = [
    { id: "unsuccessfulLoginAttempt", name: "Unsuccessful login attempt" },
    { id: "subjectBlocked", name: "Blocked after unsuccessful login attempt" },
    {
      id: "subjectLogged",
      name: "Logged in via mobile app using native Login",
    },
    { id: "subjectInvited", name: "Invited subject" },
    { id: "activatedAccount", name: "Activated account" },
    {
      id: "completedResetPassword",
      name: "Completed reset password procedure",
    },
    { id: "changedPassword", name: "Сhanged password" },
    {
      id: "requestToRestorePassword",
      name: "Sent request to restore password sent",
    },
    { id: "resetPassword", name: "Reset password for subject" },
  ];

  const filterItems = [
    { name: "Date peroid", id: "period", period: true },
    { name: "Subject", id: "displayId", children: subjectsList },
    { name: "Action type", id: "actionType", children: actionTypes },
  ];

  const exportTrail = () => {
    ApiTrails.exportSubjectTrails();
  };

  return (
    <Body disabledPaddings={true}>
      <AuditTrailHeader
        t={t}
        filterItems={filterItems}
        updateFilterValue={updateFilterValue}
        exportTrail={exportTrail}
      />
      <div className={classNames("lf-content")}>
        <InfinityTable
          data={trails}
          fetchData={fetchData}
          hasMore={!!hasMore}
          tableColumns={columns}
          isLoading={loading}
        />
      </div>
    </Body>
  );
};

export default SubjectsTrailTable;
