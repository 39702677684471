import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import CohortTable from "./cohort-table";
import CohortCard from "./cohort-card/cohort-card";
import ApiCohortManagement from "../../../api/cohort-management";
import useNotification from "../../../components/notification/notification";
import "./styles.css";
import { Body } from "../../../components/main-body/body";
import { useForm } from "antd/es/form/Form";
import { Modal } from "antd";
import debounce from "../../../common/utils/debounce";

const DeleteModalComponent = ({
  item,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={item?.value}
      open={isModalOpen}
      onOk={() => {
        handleOk(item.id, item?.value);
      }}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      okText="Delete"
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to delete this cohort?</p>
    </Modal>
  );
};

const CohortManagement = () => {
  const [cohorts, setCohorts] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [editMode, setEditMode] = useState();
  const [selectedCohort, setSelectedCohort] = useState({});
  const [form] = useForm();
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const openCohortDrawer = (edit) => {
    if (selectedCohort?.value) {
      setSelectedCohort({});
    }
    if (edit) {
      setEditMode(true);
    }
    setOpen(true);
  };

  const closeCohortDrawer = () => {
    setSelectedCohort({});
    setEditMode(false);
    setOpen(false);
  };

  const getCohorts = (deselect, query = "") => {
    ApiCohortManagement.getCohorts({query}).then(({ data }) => {
      const { cohorts } = data || {};

      setCohorts(cohorts);
    });
  };

  const debouncedGetCohorts = useCallback(debounce(getCohorts, 400), [{}, selectedCohort.id]);

  const handleUpdateSearchValue = (value) => {
    setSearchValue(value);
    debouncedGetCohorts(false, value);
  };

  const headerProps = useMemo(
    () => ({
      title: "Cohort management",
      create: {
        label: t("administration.cohort.create"),
        onClick: () => openCohortDrawer(true),
      },
      search: {
        value: searchValue,
        onHandleChange: handleUpdateSearchValue,
      },
    }),
    [isOpen, selectedCohort]
  );

  useEffect(() => {
    getCohorts();
  }, []);

  const createCohort = (cohort) => {
    closeCohortDrawer();
    ApiCohortManagement.addCohort(cohort)
      .then(() => {
        getCohorts();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.cohort.notification.created", {
                cohort: cohort.value,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.cohort.notification.${
                  e?.response?.data?.error || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const updateCohort = (id, cohort) => {
    closeCohortDrawer();
    ApiCohortManagement.updateCohort(id, cohort)
      .then(() => {
        getCohorts();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.cohort.notification.updated", {
                cohort: cohort.value,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.cohort.notification.${
                  e?.response?.data?.error || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const deleteCohort = (id, value) => {
    setIsDeleteModalOpen(false);
    closeCohortDrawer();
    ApiCohortManagement.deleteCohort(id)
      .then(() => {
        getCohorts();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.cohort.notification.deleted", {
                cohort: value,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.cohort.notification.${
                  e?.response?.data?.error || "error"
                }`
              )}
            </p>
          ),
        });
      })
      .finally(() => {
        getCohorts();
      });
  };

  const handleSelectCohort = (cohort) => {
    setSelectedCohort(cohort);
    setOpen(true);
  };

  const toEditMode = () => {
    setEditMode(true);
  };

  const onHandleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const onCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedCohort, editMode]);

  return (
    <Body header={headerProps}>
      <CohortTable data={cohorts} onHandleRowClick={handleSelectCohort} />
      <CohortCard
        cohort={selectedCohort}
        isOpen={isOpen}
        close={closeCohortDrawer}
        editMode={editMode}
        toEditMode={toEditMode}
        createCohort={createCohort}
        updateCohort={updateCohort}
        deleteCohort={onHandleDelete}
        form={form}
      />
      <DeleteModalComponent
        item={selectedCohort}
        isModalOpen={isDeleteModalOpen}
        handleCancel={onCloseDeleteModal}
        handleOk={deleteCohort}
      />
    </Body>
  );
};

export default CohortManagement;
