import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import TaskTable from "./task-table";
import TaskCard from "./task-card/task-card";
import ApiTaskManagement from "../../../api/task-managements";
import useNotification from "../../../components/notification/notification";
import "./styles.css";
import { Body } from "../../../components/main-body/body";
import { Modal } from "antd";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";
import debounce from "../../../common/utils/debounce";
// const leftSideNavModules = [
//   { id: "dashboard", name: "Summary dashboard", link: true },
//   { id: "subject-management", name: "Subjects management", link: true },
//   { id: "user-management", name: "User management", link: true },
//   { id: "audit-trail", name: "Audit trail", link: true },
//   { id: "task-management", name: "Task management", default: true, link: true },
// ];

const DeleteModalComponent = ({
  title,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      okText="Delete"
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to delete this task?</p>
    </Modal>
  );
};

const TaskManagement = ({ mode }) => {
  const [tasks, setTasks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pages, setPages] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [isOpenDeleteTaskModal, setOpenDeleteTaskModal] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilters] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const handleNavigate = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const openTaskDrawer = (edit) => {
    handleNavigate(`/administration/tasks/${selectedTask.id}`);
    if (edit) {
      setEditMode(true);
    }
    if (selectedTask.id) {
      handleNavigate(`/administration/tasks/${selectedTask.id}/edit`);
    } else {
      handleNavigate(`/administration/tasks/create`);
    }
    setOpen(true);
  };
  const cancelEditing = () => {
    handleNavigate(`/administration/tasks/${selectedTask.id}`);
    setEditMode(false);
  }
  const closeTaskDrawer = () => {
    handleNavigate(`/administration/tasks`);
    setEditMode(false);
    setOpen(false);
    setSelectedTask({});
  };

  const getTasks = async (deselect, query = "") => {
    return ApiTaskManagement.getTasks({ query, ...filters  }).then(({ data }) => {
      const { taskManagements, categories, pages } = data || {};

      setTasks(taskManagements);
      setCategories(categories);
      setPages(pages);
    });
  };

  const debouncedGetTasks = useCallback(debounce(getTasks, 400), [filters, selectedTask.id]);

  const handleUpdateSearchValue = (value) => {
    setSearchValue(value);
    debouncedGetTasks(false, value);
  };

  const headerProps = useMemo(
    () => ({
      title: "Task management",
      create: {
        label: t("task_management.create"),
        onClick: () => openTaskDrawer(true),
      },
      search: {
        value: searchValue,
        onHandleChange: handleUpdateSearchValue,
      },
    }),
    [searchValue, isOpen]
  );

  useEffect(() => {
    getTasks();
  }, [filters]);

  useEffect(() => {
    if (!id && isOpen) {
      setOpen(false);
      setSelectedTask({});
    }
  }, [id, isOpen]);

  useEffect(() => {
    if (id && tasks.length) {
      const task = tasks.find((task) => task.id === id);
      if (task) {
        setSelectedTask(task);
        setOpen(true);
      }
    } else {
      setSelectedTask({});
    }
  }, [id, tasks]);

  useEffect(() => {
    if (mode === "edit" || mode === "create") {
      setEditMode(true);
    } else {
      setEditMode(false);
    }

    if (mode === "create") {
      setOpen(true);
      setSelectedTask({});
    }
  }, [mode]);

  const isNewTaskValid = (task) => {
    let isValid = true;

    if (!task.title || task.title?.trim() === "") {
      openNotification({
        type: "error",
        message: <p>{t(`task_management.notification.no_title`)}</p>,
      });

      isValid = false;
    }

    if (!task.description || task.description?.trim() === "") {
      openNotification({
        type: "error",
        message: <p>{t(`task_management.notification.no_description`)}</p>,
      });

      isValid = false;
    }
    if (!task.avatar) {
      openNotification({
        type: "error",
        message: <p>{t(`task_management.notification.no_avatar`)}</p>,
      });

      isValid = false;
    }

    return isValid;
  };

  const createTask = (task) => {
    const isValid = isNewTaskValid(task);
    if (!isValid) return;

    // closeTaskDrawer();
    setEditMode(false);
    ApiTaskManagement.addTask(task)
      .then((response) => {
        const createdTaskId = response.data.dailyTask.id;
        getTasks().then(() => {
          openNotification({
            type: "success",
            message: (
              <p>
                {t("task_management.notification.created", {
                  task: task.title,
                })}
              </p>
            ),
          });
          handleNavigate(`/administration/tasks/${createdTaskId}`);
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `task_management.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const updateTask = (id, task) => {
    // closeTaskDrawer();
    setEditMode(false);
    handleNavigate(`/administration/tasks/${task.id}`);

    ApiTaskManagement.updateTask(id, task)
      .then(() => {
        getTasks().then(() => {
          openNotification({
            type: "success",
            message: (
              <p>
                {t("task_management.notification.updated", {
                  task: task.title,
                })}
              </p>
            ),
          });
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `task_management.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const deleteTask = (id, title) => {
    closeTaskDrawer();
    ApiTaskManagement.deleteTask(id)
      .then(() => {
        getTasks();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("task_management.notification.deleted", {
                task: title,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `task_management.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const handleSelectTask = (task) => {
    handleNavigate(`/administration/tasks/${task.id}`);
    setSelectedTask(task);
    setOpen(true);
  };

  const toEditMode = () => {
    handleNavigate(`/administration/tasks/${selectedTask.id}/edit`);
    setEditMode(true);
  };

  const openDeleteModal = () => {
    setOpenDeleteTaskModal(true);
  };

  const closeDeleteModal = () => {
    setOpenDeleteTaskModal(false);
  };

  const handleDeleteOk = () => {
    deleteTask(selectedTask.id, selectedTask.title);
    closeDeleteModal();
  };

  const handleDeleteCancel = () => {
    closeDeleteModal();
  };

  const updateFilterValue = (value) => setFilters(value);

  return (
    <Body header={headerProps}
      filters={
        <DropdownFilters
          filtersBy={[
            { name: "Category", id: "categoryIds", children: categories }
          ]}
          updateFilterValue={updateFilterValue}
        />
      }
    >
        <TaskTable
          data={tasks}
          onHandleRowClick={handleSelectTask}
        />
        <TaskCard
          task={selectedTask}
          categories={categories}
          isOpen={isOpen}
          close={closeTaskDrawer}
          editMode={editMode}
          toEditMode={toEditMode}
          createTask={createTask}
          updateTask={updateTask}
          deleteTask={openDeleteModal}
          cancelEditing={cancelEditing}
          pages={pages}
        />
        <DeleteModalComponent
          isModalOpen={isOpenDeleteTaskModal}
          handleOk={handleDeleteOk}
          handleCancel={handleDeleteCancel}
          title={selectedTask.title}
        />
    </Body>
  );
};

export default TaskManagement;
