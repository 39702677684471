import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const getScenarios = (query) => axios.get(toQueryString("api/v1/onboarding-survey-managements?", query));

const getScenarioById = (id) =>
  axios.get(`api/v1/onboarding-survey-managements/${id}`);

const deleteScenario = (id) =>
  axios.delete(`api/v1/onboarding-survey-managements/${id}`);

const updateScenario = (id, params) =>
  axios.put(`api/v1/onboarding-survey-managements/${id}`, params);

const updateScenarioVersion = (scenarioVersionId, params) =>
  axios.put(
    `api/v1/onboarding-survey-managements/version/${scenarioVersionId}`,
    params
  );

const createScenario = () => axios.post("api/v1/onboarding-survey-managements");

const activateScenario = (versionId) =>
  axios.put(
    `api/v1/onboarding-survey-managements/version/${versionId}/activate`
  );

const discardScenarioChanges = (versionId) =>
  axios.put(
    `api/v1/onboarding-survey-managements/version/${versionId}/discard`
  );

const duplicateScenario = (scenarioId) =>
  axios.post(`api/v1/onboarding-survey-managements/${scenarioId}/duplicate`);

const ApiOnboardingManagement = {
  getScenarios,
  getScenarioById,
  deleteScenario,
  updateScenario,
  updateScenarioVersion,
  createScenario,
  activateScenario,
  discardScenarioChanges,
  duplicateScenario,
};

export default ApiOnboardingManagement;
