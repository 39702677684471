import { Spin, Table } from "antd";
import "./table-cell/table-cell.css";
import "./table.css";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const TableComponent = ({ columns, data, onHandleRowClick, isLoading, onChange }) => {
  const [loading, setLoading] = useState(true);
  const [fadeInClass, setFadeInClass] = useState("");

  useEffect(() => {
    if (data) {
      setLoading(false);
      setFadeInClass("fade-in-row");
    }
  }, [data]);

  return (
    <Table
      className="lf-table assets-list__table user-management__table"
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={(item, index) => item?.id || index}
      onRow={(item) => ({
        onClick: () => {
          onHandleRowClick?.(item);
        },
      })}
      loading={{
        spinning: !!(loading || isLoading),
        indicator: (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        ),
      }}
      onChange={onChange}
      rowClassName={`assets-list__table__row clickable ${fadeInClass}`}
      data-testid="users-table"
      locale={{
        emptyText: <div style={{ height: 100 }}></div>,
      }}
    />
  );
};

export default TableComponent;
