import "./styles.css";
import useScenarioSimulatorController from "./controllers/useSimulatorController";
import MultipleChoice from "./components/multiple-choice";
import { useEffect, useMemo, useState } from "react";
import Question from "./components/question";
import Task from "./components/task";
import SuggestionsCarousel from "./components/suggetions";
import TextInput from "./components/text-input";
import SingleChoice from "./components/single-choice";
import BehaviorRulesSelector from './components/behavior-rules-selector';
import Picker from "./components/picker";
import DateTimePicker from "./components/dateTimePicker";
import TreeDotsLoader from "./components/tree-dots-loader/tree-dots-loader";
import FitFactCard from "./components/fitfact-card";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import ApiScenariosManagement from "../../api/scenarios-management";
import { isItemElse } from '../hooks/useElseValue';

const ScenarioSimulator = ({
  id,
  scenario,
  tasks,
  scenarioVersionId,
}) => {
  const [facts, setFacts] = useState([]);
  const [isReady, setReady] = useState();
  const [embeddedScenarios, setEmbeddedScenarios] = useState({});
  const [suggestionArticles, setSuggestionArticles] = useState([]);
  const { history, setAnswer, inProcess } =
    useScenarioSimulatorController(scenario, id, embeddedScenarios, isReady);

  const setAnswerValue = (id, answer) => {
    const isAlreadyAnswered = history.find((node) => (node?.internalId ?? node?.id) === id && node?.answer !== undefined);

    setAnswer(id, answer, isAlreadyAnswered);
  }

  useEffect(() => {
    ApiScenariosManagement.getSimulatorResources(scenarioVersionId).then(
      (r) => {
        setSuggestionArticles(r.data.pages || {})
        setFacts(r.data.facts || []);
        setEmbeddedScenarios(r.data.embeddedScenarios);
        setReady(true);
      }
    );
  }, [scenario]);

  const renderItem = ({ data, id, internalId }) => {
    if (data.nodeType?.includes("task:")) {
      return (
        <Task
          key={data.id}
          data={tasks.find((t) => t.title === (data.name || data.title))}
        />
      );
    }

    const nodeId = data?.id ?? internalId ?? id;

    switch (data.nodeType) {
      case "message:single":
        return (
          <SingleChoice
            key={nodeId}
            data={data}
            answeredLabel={
              history.find((node) => (node?.internalId ?? node?.id) === nodeId)?.answer?.label
            }
            elseResponse={history.find((node) => (node?.internalId ?? node?.id) === nodeId)?.answer?.elseValue}
            setAnswer={(answer) => setAnswerValue(nodeId, answer)}
          />
        );
      case "message:multi": {
        const answeredLabel = history
          .find((node) => (node?.internalId ?? node?.id) === nodeId);
        const resolvedAnsweredLabel = answeredLabel?.answer?.answers ?? answeredLabel?.answers;
        const elseResponse = resolvedAnsweredLabel?.find?.(item => isItemElse(item))?.elseValue;

        return (
          <MultipleChoice
            key={nodeId}
            data={data}
            answeredLabel={resolvedAnsweredLabel}
            elseResponse={elseResponse}
            setAnswer={(answer) => setAnswerValue(nodeId, answer)}
          />
        );
      }

      case "message:info":
        return <Question text={data.message} />;

      case "message:suggestions":
        return (
          <SuggestionsCarousel
            key={nodeId}
            pages={suggestionArticles[nodeId] || []}
          />
        );

      case "message:input":
        return (
          <TextInput
            key={nodeId}
            data={data}
            answeredLabel={
              history.find((node) => (node?.internalId ?? node?.id) === nodeId)?.answer
            }
            setAnswer={(answer) => setAnswerValue(nodeId, answer)}
          />
        );

      case "message:picker":
        return (
          <Picker
            key={nodeId}
            data={data}
            answeredLabel={
              history.find((node) => (node?.internalId ?? node?.id) === nodeId)?.answer
            }
            setAnswer={(answer) => setAnswerValue(nodeId, answer)}
          />
        );

      case "message:date":
        return (
          <DateTimePicker
            key={data.id}
            data={data}
            answeredLabel={
              history.find((node) => (node?.internalId ?? node?.id) === nodeId)?.answer
            }
            setAnswer={(answer) => setAnswerValue(nodeId, answer)}
          />
        );

      case "fit:facts":
        const factsData = Object.entries(facts).find(([key]) => key === nodeId)?.[1];

        if (!factsData) {
          return null;
        };

        return (
          <FitFactCard
            key={nodeId}
            data={factsData}
          />
        );

      case "if:condition-behavior":
        return (
          <BehaviorRulesSelector
            key={data.id}
            data={data}
            answeredLabel={
              history.find((node) => node?.id === id)?.answer
            }
            setAnswer={(answer) => setAnswerValue(id, answer)}
          />
        );

      default:
        return;
    }
  };

  const renderHistory = useMemo(() => {
    return history.map((item) => renderItem(item));
  }, [history, setAnswer]);

  return (
    <div className="scenario-simulator-layout">
      <div className="scenario-simulator-content-container">
        {isReady ? (
          <>
            {renderHistory}
            {inProcess && <TreeDotsLoader />}
          </>
        ) : (
          <Spin
            className="scenario-simulator-content-loader"
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          />
        )}
      </div>
    </div>
  );
};

export default ScenarioSimulator;
