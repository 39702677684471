import { DrawerHeader } from "../../../../../components/drawer/drawer-header";
import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Drawer } from "../../../../../components/drawer/drawer";
import InfinityTable from "../../../../../components/infinity-table/infinity-table";
import StatisticChart from "../../../../../components/statistic-chart/statistic-chart";
import DropdownFilters from "../../../../../components/dropdown-filters/dropdown-filters";
import ApiEngagement from "../../../../../api/engagement";
import InfoCardWrapper from "../../../../../components/info-card/info-card-wrapper";

const ArticleEngagementCardInside = memo(
  ({
    engagement,
    close,
    subjects,
    cohorts,
    initialPeriodFilter,
    initialCohortsFilter
  }) => {
    const { t } = useTranslation();
    const [localEngagement, setLocalEngagement] = useState(engagement);
    const [filters, setFilters] = useState({
      cohortIds: initialCohortsFilter?.cohortIds || null,
      period: initialPeriodFilter || null,
      subjectIds: null,
    });
    const filterItems = [
      { name: "Date peroid", id: "period", period: true },
      { name: "Subject ID", id: "subjectIds", children: subjects },
      { name: "Cohorts", id: "cohortIds", children: cohorts },
    ];
    const navigate = useNavigate();

    const fetchFilteredEngagements = (currentFilters) => {
      if (Array.isArray(currentFilters.period)) {
        currentFilters = {
          ...currentFilters,
          createdAtEnd: currentFilters.period?.[currentFilters.period.length - 1]?.end,
          createdAtStart: currentFilters.period?.[currentFilters.period.length - 1]?.start,
        };
      }
      ApiEngagement.getEngagementById(engagement.id, currentFilters).then((response) => {
        const updatedEngagement = {
          ...response.data.engagement,
        };
        setLocalEngagement(updatedEngagement);
      });
    };

    const toSubjectPage = (subjectId) => {
      navigate(`/subject-management/subject/${subjectId}#profile`);
    };

    useEffect(() => {
      fetchFilteredEngagements(filters);
    }, [filters]);

    useEffect(() => {
      if (initialPeriodFilter) {
        if (filters.period !== initialPeriodFilter) {
          const updatedFilters = { ...filters, period: initialPeriodFilter };
          setFilters(updatedFilters);
          fetchFilteredEngagements(updatedFilters);
        }
      }
    }, [initialPeriodFilter]);

    const transformEngagementsToData = (engagementData) => {
      return [
        {
          title: engagementData.likesCount || 0,
          subtitle: t("engagement.card.likes"),
          isPercent: false,
          isLike: true
        },
        {
          title: engagementData.dislikesCount || 0,
          subtitle: t("engagement.card.dislikes"),
          isPercent: false,
          isDislike: true
        },
        {}
      ];
    };
    const data = transformEngagementsToData(localEngagement);

    const columnsResponded = [
      {
        title: t("engagement.card.subjectId"),
        dataIndex: "subjectId",
        key: "subjectId",
        render: (_, engagement) => <span>{engagement.subject.displayId}</span>,
        width: "20%",
      },
      {
        title: t("engagement.card.engagement"),
        dataIndex: "engagement",
        key: "engagement",
        render: (_, engagement) => <span style={{ textTransform: 'capitalize' }}>{engagement.actionType}</span>,
        width: "40%",
      },
      {
        title: t("engagement.card.date"),
        dataIndex: "date",
        key: "date",
        render: (_, engagement) =>
          engagement.createdAt
            ? `${moment(engagement.createdAt).utc().format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A",
        width: "40%",
      },
    ];

    const updateFilterValue = (filter) => {
      setFilters(filter);
    };
    return (
      <>
        <DrawerHeader
          badge={localEngagement?.Category?.name}
          title={localEngagement.title}
          subtitle={localEngagement?.tags?.map(t=>t.name).join(", ")}
          onClose={close}
        />
       <div style={{ paddingBottom: 16 }} />
        <DropdownFilters
          filtersBy={filterItems}
          updateFilterValue={updateFilterValue}
          filter={{ ...initialPeriodFilter, ...initialCohortsFilter }}
          solidBorder={true}
        />
        <div style={{ paddingBottom: 20 }} />
        <InfoCardWrapper data={data} />
        <div style={{ paddingBottom: 12 }} />
        <StatisticChart 
          data={localEngagement.engagementStats}
          type='split'
        />
        <div style={{ paddingBottom: 12 }} />
        <InfinityTable
          data={localEngagement.engagements}
          tableColumns={columnsResponded}
          onHandleRowClick={(record) => toSubjectPage(record.subjectId)}
        />
      </>
    );
  }
);

const ArticleEngagementCard = ({
  engagement,
  isOpen,
  close,
  subjects,
  cohorts,
  initialPeriodFilter,
  initialCohortsFilter
}) => {
  return (
    <Drawer open={isOpen} width={800} closable={false} onClose={close}>
      <div style={{ paddingBottom: 48 }}>
        {isOpen && (
          <ArticleEngagementCardInside
            engagement={engagement}
            close={close}
            subjects={subjects}
            cohorts={cohorts}
            initialPeriodFilter={initialPeriodFilter}
            initialCohortsFilter={initialCohortsFilter}
          />
        )}
      </div>
    </Drawer>
  );
};

export default ArticleEngagementCard;
