import "./info-card.css";
import { Progress } from "antd";
import likeImage from "../../assets/like.svg";
import dislikeImage from "../../assets/dislike.svg";

const InfoCard = ({ title, subtitle, isPercent = false, isLike = false, isDislike = false }) => {
  const isEmpty = !title && !subtitle;

  return (
    <div className={`info-card ${isEmpty ? "info-card--empty" : ""}`}>
      {!isEmpty && (
        <>
          <div className="info-card__title">
            {isPercent ? (
              <span
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Progress
                  type="circle"
                  percent={title}
                  width={25}
                  strokeLinecap="butt"
                  strokeWidth={25}
                  strokeColor="#2D7FFA"
                  trailColor="rgba(45, 127, 250, 0.2)"
                  format={() => ""}
                />
                <span>{title}<span style={{fontSize: 18}}>%</span></span>
              </span>
            ) : (
              <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {title}
                {isLike && <img src={likeImage} alt="Like" className="info-card__icon" />}
                {isDislike && <img src={dislikeImage} alt="Dislike" className="info-card__icon" />}
              </span>
            )}
          </div>
          <p className="info-card__subtitle">{subtitle}</p>
        </>
      )}
    </div>
  );
};

export default InfoCard;