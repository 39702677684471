import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";
import Header from "../../../components/header/header";

const AuditAnalyticsHeader = ({
  title,
  filterItems,
  updateFilterValue,
  header
}) => {
  return (
    <Header
      title={title}
      search={header.search}
      filters={
        <DropdownFilters
          filtersBy={filterItems}
          updateFilterValue={updateFilterValue}
        />
      }
    />
  );
};

export default AuditAnalyticsHeader;
