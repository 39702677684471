import Chart from "../../../components/chart/chart";
import SubjectProgressCard from "./card";
import AdherenceTable from "./adherence-table";
import ApiSubjectsDashboard from "../../../api/subjects-dashboard";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PageContentLoader from "../../../components/loader/page-content-loader";
import moment from "moment";

const defaultStartDate = moment().subtract(30, "days").utc().toISOString();
const defaultEndDate = moment().utc().toISOString();

const SubjectDashboard = ({ filterOptions = {} }) => {
  const [pointsByDates, setPointsByDates] = useState({});
  const [totalAdherences, setTotalAdherences] = useState({});
  const [totalPointsSum, setTotalPointsSum] = useState(0);
  const [totalSubjects, setTotalSubjects] = useState(0);
  const [subjectsPerDay, setSubjectsPerDay] = useState([]);
  const [totalAdherenceSum, setTotalAdherenceSum] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const chartContainer = useRef();
  const [chartWidth, setChartWidth] = useState(0);
  const [tab, setTab] = useState(1);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [sorter] = useState({ field: null, order: null });

  const fetchSubjects = ({ sortField = null, sortOrder = null } = {}) => {
    setLoading(true);
    ApiSubjectsDashboard.getScores({
      ...filterOptions,
      sortField,
      sortOrder,
      periodEnd:
        filterOptions.period?.[filterOptions.period.length - 1]?.end ||
        defaultEndDate,
      periodStart:
        filterOptions.period?.[filterOptions.period.length - 1]?.start ||
        defaultStartDate,
    })
      .then((r) => {
        setTotalSubjects(r.data.totalSubjectsCount)
        setSubjectsPerDay(r.data.subjectList)
        setPointsByDates(r.data.totalScores);
        setTotalPointsSum(r.data.totalScoresSum);
        setSubjects(r.data.totalScoreBySubjects);
        setTotalAdherenceSum(r.data.totalAdherenceSum * 100);
        setTotalAdherences(r.data.totalAdherences);
        setTasks(r.data.totalAdherenceDailyTasks);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  // useEffect(() => {
  //   ApiSubjectsDashboard.getScores({
  //     ...filterOptions,
  //     periodEnd:
  //       filterOptions.period?.[filterOptions.period.length - 1]?.end ||
  //       defaultEndDate,
  //     periodStart:
  //       filterOptions.period?.[filterOptions.period.length - 1]?.start ||
  //       defaultStartDate,
  //   })
  //     .then((r) => {
  //       setPointsByDates(r.data.totalScores);
  //       setTotalPointsSum(r.data.totalScoresSum);
  //       setSubjects(r.data.totalScoreBySubjects);
  //       setTotalAdherenceSum(r.data.totalAdherenceSum * 100);
  //       setTotalAdherences(r.data.totalAdherences);
  //       setTasks(r.data.totalAdherenceDailyTasks);
  //     })
  //     .then(() => {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 500);
  //     });
  // }, [filterOptions]);

  useEffect(() => {
    // Викликаємо fetchSubjects при зміні фільтрів або сортування
    fetchSubjects({ sortField: sorter.field, sortOrder: sorter.order });
  }, [filterOptions, sorter]);

  useEffect(() => {
    setChartWidth(chartContainer.current?.offsetWidth - 20);
  }, [chartContainer.current]);

  return (
    <div style={{ position: "relative" }}>
      {isLoading && <PageContentLoader />}
      <div className="dashboard-item-container">
        <div className="dashboard-summary-cards">
          <div
            onClick={() => setTab(1)}
            className={tab === 1 && "dashboard-summary-cards__border-bottom"}
          >
            <p style={{ fontSize: 32, marginTop: 8 }}>{Math.round(totalPointsSum * 10) / 10}</p>
            <p style={{ fontSize: 13 }}>
              {t("subject_management.daily_points")}
            </p>
          </div>

          <div
            onClick={() => setTab(2)}
            className={tab === 2 && "dashboard-summary-cards__border-bottom"}
          >
            <p style={{ fontSize: 32, marginTop: 8 }}>
              {Math.floor(totalAdherenceSum)}%
            </p>
            <p style={{ fontSize: 13 }}>{t("subject_management.adherence")}</p>
          </div>

          <div
            onClick={() => setTab(3)}
            className={tab === 3 && "dashboard-summary-cards__border-bottom"}
          >
            <p style={{ fontSize: 32, marginTop: 8 }}>{totalSubjects}</p>
            <p style={{ fontSize: 13 }}>{t("subject_management.subjects")}</p>
          </div>
        </div>

        <div style={{ padding: 20 }} ref={chartContainer}>
          <Chart
            pointsByDates={
              tab === 1
                ? pointsByDates
                : tab === 2
                ? totalAdherences
                : subjectsPerDay
            }
            width={chartWidth}
          />
        </div>
      </div>
      <SubjectProgressCard tasks={tasks} />
      <AdherenceTable subjects={subjects} fetchSubjects={fetchSubjects} />
      {/* <UserActivityTable /> */}
    </div>
  );
};

export default SubjectDashboard;
