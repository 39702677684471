import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const getCohorts = (query) => axios.get(toQueryString("api/v1/cohorts?", query));

const addCohort = (cohort) => axios.post("api/v1/cohorts", cohort);

const updateCohort = (id, cohort) => axios.put(`api/v1/cohorts/${id}`, cohort);

const deleteCohort = (id) => axios.delete(`api/v1/cohorts/${id}`);

const ApiCohortManagement = {
  getCohorts,
  addCohort,
  updateCohort,
  deleteCohort,
};

export default ApiCohortManagement;
