import classNames from "classnames";
import { Body } from "../../../../components/main-body/body";
import HealthItemCard from "./components/card";
import HealthDataTable from "./components/table";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import dayjs from "dayjs";

const sufixes = {
  dailyStepCount: "average steps",
  restingHeartRate: "average bpm",
  sleepDuration: "average hours",
  activeEnergy: "average cal",
};

const HealthData = () => {
  const [subjectHealths, setSubjectHealths] = useState([]);
  const [average, setAverage] = useState({});
  const [period, setPeriod] = useState({
    periodStart: moment().subtract(14, "days"),
    periodEnd: moment().subtract(1, "days"),
  });

  const { id } = useParams();

  const downloadData = () => {
    ApiSubjectsManagement.exportHealth(id, {
      ...period,
      content: true,
    });
  };

  const updatePeriod = (dates) => {
    setPeriod({
      ...period,
      periodStart: moment(dates[0].$d),
      periodEnd: moment(dates[1].$d),
    });
  };

  const getSubjectHealth = () => {
    ApiSubjectsManagement.getHealth(id, period).then((r) => {
      setSubjectHealths(r.data.subjectHealths);
      setAverage(r.data.average);
    });
  };

  useEffect(() => {
    getSubjectHealth(period);
  }, [period, id]);

  return (
    <Body
      header={{
        title: "Health data",
        create: {
          label: "Export",
          onClick: () => downloadData(),
        },
        period: (
          <DatePicker.RangePicker
            defaultValue={[dayjs(period.periodStart), dayjs(period.periodEnd)]}
            onChange={(dates) => {
              updatePeriod(dates);
            }}
            format="DD.MM.YYYY"
          />
        ),
      }}
      scroll
    >
      <div
        className={classNames("health-data-cards-container", {
          // "health-data-cards-container--duo": !Object.keys(average).length % 2,
          // "health-data-cards-container--trio": Object.keys(average).length % 2,
          "health-data-cards-container--trio": true,
        })}
      >
        <HealthItemCard
          item={{
            title: "Step count",
            average: Math.floor(average.dailyStepCount),
            type: "dailyStepCount",
            sufix: sufixes["dailyStepCount"],
            chartValues: subjectHealths.map((health) => ({
              name: health.doneAt,
              value: Math.floor(health.dailyStepCount) || 0,
            })),
          }}
        />
        <HealthItemCard
          item={{
            title: "Resting heart rate",
            average: Math.floor(average.restingHeartRate),
            type: "restingHeartRate",
            sufix: sufixes["restingHeartRate"],
            chartValues: subjectHealths.map((health) => ({
              name: health.doneAt,
              value: Math.floor(health.restingHeartRate) || 0,
            })),
          }}
        />
        <HealthItemCard
          item={{
            title: "Active energy burned",
            average: Math.floor(average.activeEnergy),
            type: "activeEnergy",
            sufix: sufixes["activeEnergy"],
            chartValues: subjectHealths.map((health) => ({
              name: health.doneAt,
              value: Math.floor(health.activeEnergy) || 0,
            })),
          }}
        />
      </div>
      <HealthDataTable data={subjectHealths} />
    </Body>
  );
};

export default HealthData;
