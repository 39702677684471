import { DrawerHeader } from "../../../../../components/drawer/drawer-header";
import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Drawer } from "../../../../../components/drawer/drawer";
import InfinityTable from "../../../../../components/infinity-table/infinity-table";
import ApiAnalytics from "../../../../../api/analytics";
import InfoCardWrapper from "../../../../../components/info-card/info-card-wrapper";
import StatisticChart from "../../../../../components/statistic-chart/statistic-chart";
import DropdownFilters from "../../../../../components/dropdown-filters/dropdown-filters";
import { Tabs } from "antd";
import PageContentLoader from "../../../../../components/loader/page-content-loader";
const { TabPane } = Tabs;

const AnalyticsCardInside = memo(
  ({
    analytics,
    close,
    subjects,
    cohorts,
    initialPeriodFilter,
  }) => {
    const [filters, setFilters] = useState({
      cohortIds: null,
      period: initialPeriodFilter || null,
      subjectIds: null,
    });
    const filterItems = [
      { name: "Date peroid", id: "period", period: true },
      { name: "Subject ID", id: "subjectIds", children: subjects },
      { name: "Cohorts", id: "cohortIds", children: cohorts },
    ];
    const [loading, setLoading] = useState(true);
    const [localAnalytics, setLocalAnalytics] = useState(analytics); 
    const { t } = useTranslation();
    const navigate = useNavigate();

    const fetchFilteredAnalytics = (currentFilters) => {
      // setLoading(true); // Увімкнути лоадер
      if (Array.isArray(currentFilters.period)) {
        currentFilters = {
          ...currentFilters,
          createdAtEnd: currentFilters.period?.[currentFilters.period.length - 1]?.end,
          createdAtStart: currentFilters.period?.[currentFilters.period.length - 1]?.start,
        };
      }
      ApiAnalytics.getTaskAnalyticsById(analytics.id, currentFilters)
        .then((response) => {
          const updatedAnalytics = {
            ...analytics,
            ...response.data.task,
          };
          setLocalAnalytics(updatedAnalytics);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const transformAnalyticsToData = (analytics) => {
      return [
        {
          title: analytics.adherence || 0,
          subtitle: t("analytics.card.adherence_rate"),
          isPercent: true,
        },
        {
          title: analytics.statusCounts?.COMPLETED || 0,
          subtitle: t("analytics.card.complete"),
          isPercent: false,
        },
        {
          title: analytics.statusCounts?.PENDING || 0,
          subtitle: t("analytics.card.pending"),
          isPercent: false,
        },
        {
          title: analytics.statusCounts?.DISMISSED || 0,
          subtitle: t("analytics.card.dismissed"),
          isPercent: false,
        },
        {
          title: analytics.statusCounts?.INCOMPLETED || 0,
          subtitle: t("analytics.card.incomplete"),
          isPercent: false,
        }
      ];
    };

    const data = transformAnalyticsToData(localAnalytics);

    const updateFilterValue = (filter) => {
      setFilters(filter);
    };

    const [didApplyInitial, setDidApplyInitial] = useState(false);

    const toSubjectPage = (subjectId) => {
      navigate(`/subject-management/subject/${subjectId}#profile`);
    };

    useEffect(() => {
      if (initialPeriodFilter && !didApplyInitial) {
        const updatedFilters = {
          ...filters,
          period: initialPeriodFilter,
          createdAtStart: initialPeriodFilter?.[initialPeriodFilter.length-1]?.start,
          createdAtEnd: initialPeriodFilter?.[initialPeriodFilter.length-1]?.end,
        };
        setFilters(updatedFilters);
        setDidApplyInitial(true);
        return; 
      }

      fetchFilteredAnalytics(filters);

    }, [filters, initialPeriodFilter, didApplyInitial]);

    const columnsComplete = [
      {
        title: t("analytics.table.subjectId"),
        dataIndex: "subjectId",
        key: "subjectId",
        render: (_, analytics) => <span>{analytics.Subject.displayId}</span>,
        width: "30%",
      },
      {
        title: t("analytics.table.cohort"),
        dataIndex: "cohort",
        key: "cohort",
        render: (_, analytics) => <span>{analytics.cohort}</span>,
        width: "30%",
      },
      {
        title: t("analytics.table.completion_date"),
        dataIndex: "completion_date",
        key: "completion_date",
        render: (_, analytics) =>
          analytics.completedAt
            ? `${moment(analytics.completedAt).utc().format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A",
        width: "40%",
      },
    ];

    const columnsOther = [
      {
        title: t("analytics.table.subjectId"),
        dataIndex: "subjectId",
        key: "subjectId",
        render: (_, analytics) => <span>{analytics.Subject.displayId}</span>,
        width: "30%",
      },
      {
        title: t("analytics.table.cohort"),
        dataIndex: "cohort",
        key: "cohort",
        render: (_, analytics) => <span>{analytics.cohort}</span>,
        width: "30%",
      },
      {
        title: t("analytics.table.date"),
        dataIndex: "planned_date",
        key: "planned_date",
        render: (_, analytics) =>
          analytics.plannedAt
            ? `${moment(analytics.plannedAt).utc().format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A",
        width: "40%",
      },
    ];

    const columnsDismissed = [
      {
        title: t("analytics.table.subjectId"),
        dataIndex: "subjectId",
        key: "subjectId",
        render: (_, analytics) => <span>{analytics.Subject.displayId}</span>,
        width: "20%",
      },
      {
        title: t("analytics.table.cohort"),
        dataIndex: "cohort",
        key: "cohort",
        render: (_, analytics) => <span>{analytics.cohort}</span>,
        width: "20%",
      },
      {
        title: t("analytics.table.dismiss_reason"),
        dataIndex: "reason",
        key: "reason",
        render: (_, analytics) => {
          const reasonType = analytics.dismissReasonType?.toLowerCase(); // Приводимо до нижнього регістру
          if (reasonType === "other") {
            return <span>Other: {analytics.dismissReasonText}</span>;
          }
          return <span>{analytics.dismissReasonType}</span>;
        },
        width: "40%",
      },
      {
        title: t("analytics.table.dismiss_date"),
        dataIndex: "dismissedAt",
        key: "dismissedAt",
        render: (_, analytics) =>
          analytics.dismissedAt
            ? `${moment(analytics.dismissedAt).utc().format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A",
        width: "20%",
      },
    ];
    return (
      <>
        <DrawerHeader
          badge={localAnalytics?.Category?.name}
          title={localAnalytics.title}
          subtitle={localAnalytics.plans}
          onClose={close}
        />
        <div style={{ paddingBottom: 16 }} />
        <DropdownFilters
          filtersBy={filterItems}
          updateFilterValue={updateFilterValue}
          filter={initialPeriodFilter}
          solidBorder={true}
        />
        <div style={{ paddingBottom: 20 }} />
        {loading ? (
          <div style={{ position: 'relative' }}>
            <PageContentLoader />
          </div>
        ) : (
        <>
          <InfoCardWrapper data={data} />
          <div style={{ paddingBottom: 12 }} />
          <div className="analytics-tabs">
            <Tabs defaultActiveKey="1">
              <TabPane tab={t("analytics.card.complete")} key="1">
                <div style={{ paddingBottom: 12 }} />
                {localAnalytics.questionAnswerStats &&
                  Object.keys(localAnalytics.questionAnswerStats).length > 0 && (
                    <>
                      {Object.entries(localAnalytics.questionAnswerStats).map(
                        ([questionText, answersArray]) => {
                          return (
                            <div key={questionText} style={{ marginBottom: '16px' }}>
                              <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                {questionText}
                              </div>
                              <StatisticChart data={answersArray} />
                            </div>
                          );
                        }
                      )}
                    </>
                  )}
                <InfinityTable
                  data={localAnalytics?.statusGroups?.COMPLETED || []}
                  tableColumns={columnsComplete}
                  onHandleRowClick={(record) => toSubjectPage(record.subjectId)}
                />
              </TabPane>
              <TabPane tab={t("analytics.card.dismissed")} key="2">
                {localAnalytics.dismissReasonStats && localAnalytics.dismissReasonStats.length > 0 && (
                  <>
                    <StatisticChart 
                      data={localAnalytics.dismissReasonStats}
                    />
                    <div style={{ paddingBottom: 12 }} />
                  </>
                )}
                <InfinityTable
                  data={localAnalytics?.statusGroups?.DISMISSED || []}
                  tableColumns={columnsDismissed}
                  onHandleRowClick={(record) => toSubjectPage(record.subjectId)}
                />
              </TabPane>
              <TabPane tab={t("analytics.card.pending")} key="3">
                <InfinityTable
                  data={localAnalytics?.statusGroups?.PENDING || []}
                  tableColumns={columnsOther}
                  onHandleRowClick={(record) => toSubjectPage(record.subjectId)}
                />
              </TabPane>
              <TabPane tab={t("analytics.card.incomplete")} key="4">
                <InfinityTable
                  data={localAnalytics?.statusGroups?.INCOMPLETED || []}
                  tableColumns={columnsOther}
                  onHandleRowClick={(record) => toSubjectPage(record.subjectId)}
                />
              </TabPane>
            </Tabs>
          </div>
        </>
        )}
      </>
    );
  }
);

const AnalyticsCard = ({
  analytics,
  isOpen,
  close,
  subjects,
  cohorts,
  initialPeriodFilter
}) => {
  return (
    <Drawer open={isOpen} width={900} closable={false} onClose={close}>
      <div style={{ paddingBottom: 48 }}>
        {isOpen && (
          <AnalyticsCardInside
            analytics={analytics}
            close={close}
            subjects={subjects}
            cohorts={cohorts}
            initialPeriodFilter={initialPeriodFilter}
          />
        )}
      </div>
    </Drawer>
  );
};

export default AnalyticsCard;
