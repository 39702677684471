export const nodeItemsConfig = [
  {
    title: "Messages",
    items: [
      {
        title: "Text",
        titleDescription: "Send message",
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clipPath="url(#clip0_5200_13573)">
              <path d="M4.5 6H11.5" stroke="#151515" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.5 9H11.5" stroke="#151515" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.5 7.5C15.5 3.91 12.142 1 8 1C3.858 1 0.5 3.91 0.5 7.5C0.5 11.09 3.858 14 8 14C8.525 14 9.037 13.952 9.532 13.863L13.5 16V11.909C14.738 10.75 15.5 9.204 15.5 7.5Z" stroke="#151515" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_5200_13573">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        `,
        type: "GenericNode",
        nodeType: "message:info",
      },
      {
        title: "Single Choice",
        titleDescription: "Send message",
        type: "GenericNode",
        nodeType: "message:single",
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clipPath="url(#clip0_5200_13580)">
              <path d="M8 16C12.1421 16 15.5 12.6421 15.5 8.5C15.5 4.35786 12.1421 1 8 1C3.85786 1 0.5 4.35786 0.5 8.5C0.5 12.6421 3.85786 16 8 16Z" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 12C9.933 12 11.5 10.433 11.5 8.5C11.5 6.567 9.933 5 8 5C6.067 5 4.5 6.567 4.5 8.5C4.5 10.433 6.067 12 8 12Z" fill="#151515"/>
              <path d="M8 12C9.933 12 11.5 10.433 11.5 8.5C11.5 6.567 9.933 5 8 5C6.067 5 4.5 6.567 4.5 8.5C4.5 10.433 6.067 12 8 12Z" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_5200_13580">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        `,
      },
      {
        title: "Multiple Choice",
        titleDescription: "Send message",
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clip-path="url(#clip0_6667_12239)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1213 2.37868C14.4633 2.72063 14.7213 3.30067 14.86 4.51708C14.9984 5.73148 15 7.37049 15 9.67157C15 11.199 14.9969 12.2253 14.8667 13.0203C14.7456 13.7598 14.5256 14.217 14.1213 14.6213C13.7523 14.9903 13.2342 15.2309 12.2558 15.3625C11.2487 15.4979 9.91389 15.5 8 15.5C6.08611 15.5 4.75129 15.4979 3.74416 15.3625C2.76579 15.2309 2.2477 14.9903 1.87868 14.6213C1.50966 14.2523 1.26907 13.7342 1.13753 12.7558C1.00212 11.7487 1 10.4139 1 8.5C1 6.58611 1.00212 5.25129 1.13753 4.24416C1.26907 3.26579 1.50966 2.7477 1.87868 2.37868C2.2477 2.00966 2.76579 1.76907 3.74416 1.63753C4.75129 1.50212 6.08611 1.5 8 1.5C9.91389 1.5 11.2487 1.50212 12.2558 1.63753C13.2342 1.76907 13.7523 2.00966 14.1213 2.37868ZM14.8284 1.67157C16 2.84315 16 5.11929 16 9.67157C16 12.6618 16 14.1569 14.8284 15.3284C13.6569 16.5 11.7712 16.5 8 16.5C4.22876 16.5 2.34315 16.5 1.17157 15.3284C0 14.1569 0 12.2712 0 8.5C0 4.72876 0 2.84315 1.17157 1.67157C2.34315 0.5 4.22876 0.5 8 0.5C11.7712 0.5 13.6569 0.5 14.8284 1.67157ZM11.5172 7.0431C11.8172 6.75744 11.8288 6.28271 11.5431 5.98276C11.2574 5.68281 10.7827 5.67123 10.4828 5.9569L6.8 9.46429L5.51724 8.24261C5.21729 7.95695 4.74256 7.96853 4.4569 8.26847C4.17123 8.56842 4.18281 9.04315 4.48276 9.32882L6.28276 11.0431C6.57241 11.319 7.02759 11.319 7.31724 11.0431L11.5172 7.0431Z" fill="#151515"/>
            </g>
            <defs>
              <clipPath id="clip0_6667_12239">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        `,
        type: "GenericNode",
        nodeType: "message:multi",
      },
      {
        title: "Date",
        titleDescription: "Send message",
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 2C5.25 1.58579 4.91421 1.25 4.5 1.25C4.08579 1.25 3.75 1.58579 3.75 2V3.02622C2.34577 3.08454 1.48481 3.27255 0.87868 3.87868C0 4.75736 0 6.17157 0 9V9.78946C0 12.6179 0 14.0321 0.87868 14.9108C1.75736 15.7895 3.17157 15.7895 6 15.7895H10C12.8284 15.7895 14.2426 15.7895 15.1213 14.9108C16 14.0321 16 12.6179 16 9.78947V8.99989C16 6.17154 16 4.75736 15.1213 3.87868C14.5152 3.27255 13.6542 3.08454 12.25 3.02622V2C12.25 1.58579 11.9142 1.25 11.5 1.25C11.0858 1.25 10.75 1.58579 10.75 2V3.0007C10.5117 3 10.2619 3 10 3H8H6C5.73811 3 5.48834 3 5.25 3.0007V2ZM10.75 4.65068V4.0008C10.5147 4.00007 10.2651 4 10 4H8H6C5.73486 4 5.48531 4.00007 5.25 4.0008V4.65068C5.25 5.0649 4.91421 5.40068 4.5 5.40068C4.08579 5.40068 3.75 5.0649 3.75 4.65068V4.02699C3.40777 4.0419 3.10834 4.06503 2.84143 4.10092C2.13538 4.19584 1.80836 4.36322 1.58579 4.58579C1.36322 4.80836 1.19584 5.13538 1.10092 5.84143C1.07618 6.0254 1.05751 6.22482 1.04341 6.44305H14.9566C14.9425 6.22481 14.9238 6.02538 14.8991 5.84141C14.8042 5.13538 14.6368 4.80836 14.4142 4.58579C14.1916 4.36322 13.8646 4.19584 13.1586 4.10092C12.8917 4.06503 12.5922 4.0419 12.25 4.02699V4.65068C12.25 5.0649 11.9142 5.40068 11.5 5.40068C11.0858 5.40068 10.75 5.0649 10.75 4.65068ZM14.9924 7.44305H1.00759C1.00036 7.89433 1 8.40837 1 9V9.78946C1 11.2319 1.00212 12.2132 1.10092 12.948C1.19584 13.6541 1.36322 13.9811 1.58579 14.2037C1.80836 14.4262 2.13538 14.5936 2.84143 14.6885C3.57625 14.7873 4.55752 14.7895 6 14.7895H10C11.4425 14.7895 12.4237 14.7873 13.1586 14.6885C13.8646 14.5936 14.1916 14.4262 14.4142 14.2037C14.6368 13.9811 14.8042 13.6541 14.8991 12.948C14.9979 12.2132 15 11.2319 15 9.78947V8.99989C15 8.40831 14.9996 7.8943 14.9924 7.44305Z" fill="#151515"/>
          </svg>
        `,
        type: "GenericNode",
        nodeType: "message:date",
      },
      {
        title: "Suggestions",
        titleDescription: "Send message",
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clip-path="url(#clip0_6662_58872)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1213 2.37868C14.4633 2.72063 14.7213 3.30067 14.86 4.51708C14.9984 5.73148 15 7.37049 15 9.67157C15 11.199 14.9969 12.2253 14.8667 13.0203C14.7456 13.7598 14.5256 14.217 14.1213 14.6213C13.7523 14.9903 13.2342 15.2309 12.2558 15.3625C11.2487 15.4979 9.91389 15.5 8 15.5C6.08611 15.5 4.75129 15.4979 3.74416 15.3625C2.76579 15.2309 2.2477 14.9903 1.87868 14.6213C1.50966 14.2523 1.26907 13.7342 1.13753 12.7558C1.00212 11.7487 1 10.4139 1 8.5C1 6.58611 1.00212 5.25129 1.13753 4.24416C1.26907 3.26579 1.50966 2.7477 1.87868 2.37868C2.2477 2.00966 2.76579 1.76907 3.74416 1.63753C4.75129 1.50212 6.08611 1.5 8 1.5C9.91389 1.5 11.2487 1.50212 12.2558 1.63753C13.2342 1.76907 13.7523 2.00966 14.1213 2.37868ZM14.8284 1.67157C16 2.84315 16 5.11929 16 9.67157C16 12.6618 16 14.1569 14.8284 15.3284C13.6569 16.5 11.7712 16.5 8 16.5C4.22876 16.5 2.34315 16.5 1.17157 15.3284C0 14.1569 0 12.2712 0 8.5C0 4.72876 0 2.84315 1.17157 1.67157C2.34315 0.5 4.22876 0.5 8 0.5C11.7712 0.5 13.6569 0.5 14.8284 1.67157ZM6.79395 4.5C6.79395 5.05228 7.24166 5.5 7.79395 5.5C8.34623 5.5 8.79395 5.05228 8.79395 4.5C8.79395 3.94772 8.34623 3.5 7.79395 3.5C7.24166 3.5 6.79395 3.94772 6.79395 4.5ZM5.875 7.5C5.875 7.15482 6.15482 6.875 6.5 6.875H7.94939C8.29457 6.875 8.57439 7.15482 8.57439 7.5C8.57439 7.54232 8.57018 7.58366 8.56217 7.62362C8.57055 7.66444 8.57495 7.7067 8.57495 7.75V10.7952H9.5C9.84518 10.7952 10.125 11.075 10.125 11.4202C10.125 11.7654 9.84518 12.0452 9.5 12.0452H6.5C6.15482 12.0452 5.875 11.7654 5.875 11.4202C5.875 11.075 6.15482 10.7952 6.5 10.7952H7.32495V8.125H6.5C6.15482 8.125 5.875 7.84518 5.875 7.5Z" fill="#151515"/>
            </g>
            <defs>
              <clipPath id="clip0_6662_58872">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        `,
        type: "GenericNode",
        nodeType: "message:suggestions",
      },
      {
        title: "Text input",
        titleDescription: "Send message",
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M3.5 4H12.5" stroke="#151515" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 14V4" stroke="#151515" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        type: "GenericNode",
        nodeType: "message:input",
      },
            {
        title: 'Picker',
        titleDescription: 'Send message',
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1213 2.37868C14.4633 2.72063 14.7213 3.30067 14.86 4.51708C14.9984 5.73148 15 7.37049 15 9.67157C15 11.199 14.9969 12.2253 14.8667 13.0203C14.7456 13.7598 14.5256 14.217 14.1213 14.6213C13.7523 14.9903 13.2342 15.2309 12.2558 15.3625C11.2487 15.4979 9.91389 15.5 8 15.5C6.08611 15.5 4.75129 15.4979 3.74416 15.3625C2.76579 15.2309 2.2477 14.9903 1.87868 14.6213C1.50966 14.2523 1.26907 13.7342 1.13753 12.7558C1.00212 11.7487 1 10.4139 1 8.5C1 6.58611 1.00212 5.25129 1.13753 4.24416C1.26907 3.26579 1.50966 2.7477 1.87868 2.37868C2.2477 2.00966 2.76579 1.76907 3.74416 1.63753C4.75129 1.50212 6.08611 1.5 8 1.5C9.91389 1.5 11.2487 1.50212 12.2558 1.63753C13.2342 1.76907 13.7523 2.00966 14.1213 2.37868ZM14.8284 1.67157C16 2.84315 16 5.11929 16 9.67157C16 12.6618 16 14.1569 14.8284 15.3284C13.6569 16.5 11.7712 16.5 8 16.5C4.22876 16.5 2.34315 16.5 1.17157 15.3284C0 14.1569 0 12.2712 0 8.5C0 4.72876 0 2.84315 1.17157 1.67157C2.34315 0.5 4.22876 0.5 8 0.5C11.7712 0.5 13.6569 0.5 14.8284 1.67157ZM7.13391 11C7.51881 11.6667 8.48106 11.6667 8.86596 11L10.8429 7.57584C11.2278 6.90917 10.7467 6.07584 9.97688 6.07584L6.023 6.07584C5.2532 6.07584 4.77207 6.90917 5.15697 7.57584L7.13391 11Z" fill="#151515"/>
          </svg>
        `,
        type: 'GenericNode',
        nodeType: 'message:picker',
      },
      {
        title: 'FitFact',
        titleDescription: 'Interesting fact',
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clip-path="url(#clip0_7569_15479)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.86 4.51708C14.7213 3.30067 14.4633 2.72063 14.1213 2.37868C13.7523 2.00966 13.2342 1.76907 12.2558 1.63753C11.2487 1.50212 9.91389 1.5 8 1.5C6.08611 1.5 4.75129 1.50212 3.74416 1.63753C2.76579 1.76907 2.2477 2.00966 1.87868 2.37868C1.50966 2.7477 1.26907 3.26579 1.13753 4.24416C1.00212 5.25129 1 6.58611 1 8.5C1 10.4139 1.00212 11.7487 1.13753 12.7558C1.26907 13.7342 1.50966 14.2523 1.87868 14.6213C2.2477 14.9903 2.76579 15.2309 3.74416 15.3625C4.75129 15.4979 6.08611 15.5 8 15.5C9.91389 15.5 11.2487 15.4979 12.2558 15.3625C13.2342 15.2309 13.7523 14.9903 14.1213 14.6213C14.5256 14.217 14.7456 13.7598 14.8667 13.0203C14.9969 12.2253 15 11.199 15 9.67157C15 7.37049 14.9984 5.73148 14.86 4.51708ZM16 9.67157C16 5.11929 16 2.84315 14.8284 1.67157C13.6569 0.5 11.7712 0.5 8 0.5C4.22876 0.5 2.34315 0.5 1.17157 1.67157C0 2.84315 0 4.72876 0 8.5C0 12.2712 0 14.1569 1.17157 15.3284C2.34315 16.5 4.22876 16.5 8 16.5C11.7712 16.5 13.6569 16.5 14.8284 15.3284C16 14.1569 16 12.6618 16 9.67157Z" fill="#151515"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0355 4.52539H3.9646V5.53306H12.0355V4.52539ZM12.0355 7.4873H3.9646V8.49498H12.0355V7.4873Z" fill="#151515"/>
            </g>
            <defs>
              <clipPath id="clip0_7569_15479">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        `,
        type: 'GenericNode',
        nodeType: 'fit:facts',
      },
      {
        title: 'Scenario',
        titleDescription: 'Scenario',
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.616 0.67992C10.7928 0.467782 11.1081 0.43912 11.3202 0.615902L14.3202 3.1159L14.7811 3.50001L14.3202 3.88412L11.3202 6.38412C11.1081 6.5609 10.7928 6.53224 10.616 6.3201C10.4392 6.10797 10.4679 5.79268 10.68 5.6159L12.4391 4.15001H8.74317C7.81448 4.15001 6.97289 4.69694 6.59572 5.54559L6.17935 6.48242C5.90175 7.10702 5.46218 7.62639 4.923 8.00001C5.46218 8.37363 5.90175 8.893 6.17935 9.51761L6.59572 10.4544C6.97289 11.3031 7.81448 11.85 8.74317 11.85H12.4391L10.68 10.3841C10.4679 10.2073 10.4392 9.89206 10.616 9.67992C10.7928 9.46778 11.1081 9.43912 11.3202 9.6159L14.3202 12.1159L14.7811 12.5L14.3202 12.8841L11.3202 15.3841C11.1081 15.5609 10.7928 15.5322 10.616 15.3201C10.4392 15.108 10.4679 14.7927 10.68 14.6159L12.4391 13.15H8.74317C7.30073 13.15 5.99359 12.3005 5.40776 10.9824L4.99139 10.0456C4.61421 9.19694 3.77263 8.65001 2.84394 8.65001H1.29355C0.93457 8.65001 0.643555 8.359 0.643555 8.00001C0.643555 7.64103 0.93457 7.35001 1.29355 7.35001H2.84394L2.84511 7.35001C3.77334 7.34955 4.61437 6.80273 4.99139 5.95444L5.40776 5.01761C5.99359 3.69949 7.30073 2.85001 8.74317 2.85001H12.4391L10.68 1.38412C10.4679 1.20734 10.4392 0.892059 10.616 0.67992Z" fill="#6C6C89"/>
          </svg>
        `,
        type: 'GenericNode',
        nodeType: 'scenario:picker',
      },
      {
        title: 'Photo picker',
        type: 'GenericNode',
        nodeType: 'message:photo-picker',
        icon: `
          <svg width="12" height="20" viewBox="0 0 29 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" width="27.5" height="45.5" rx="5.25" stroke="#6C6C89" stroke-width="3"/>
            <circle cx="8.5" cy="8.5" r="2.75" stroke="#6C6C89" stroke-width="3"/>
          </svg>
        `,
        titleDescription: 'Take a photo',
      }
    ],
  },
  {
    title: "Logic",
    items: [
      {
        title: "If Condition",
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clipPath="url(#clip0_5200_13614)">
              <path d="M0.5 6V1H5.5" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.5 6V1H10.5" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M0.5 1L7.5 8V16" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.5 1L9.5 7" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_5200_13614">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        `,
        type: "IfConditionNode",
        nodeType: "if:condition",
        bgColor: "hsla(240, 12%, 48%, 0.08)",
      },
      {
        title: 'Behavior Rules',
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clipPath="url(#clip0_5200_13614)">
              <path d="M0.5 6V1H5.5" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.5 6V1H10.5" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M0.5 1L7.5 8V16" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.5 1L9.5 7" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_5200_13614">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        `,
        type: 'BehaviorRulesNode',
        nodeType: 'behavior:rules',
        bgColor: 'hsla(240, 12%, 48%, 0.08)'
      },
      {
        title: "Timeout",
        titleDescription: "Set timeout",
        icon: `
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clipPath="url(#clip0_5200_13622)">
              <path d="M8 16C12.1421 16 15.5 12.6421 15.5 8.5C15.5 4.35786 12.1421 1 8 1C3.85786 1 0.5 4.35786 0.5 8.5C0.5 12.6421 3.85786 16 8 16Z" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 3.5V8.5H13" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_5200_13622">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        `,
        type: "GenericNode",
        nodeType: "timer:wait",
        bgColor: "hsla(240, 12%, 48%, 0.08)",
      },
    ],
  },
  {
    title: "Tasks",
    // items: [
    //   {
    //       label: 'Nourishment',
    //       labelDescription: 'Add task',
    //       icon: `
    //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    //           <g clipPath="url(#clip0_5619_151)">
    //             <path fillRule="evenodd" clipRule="evenodd" d="M10.7094 0.157227C10.8288 0.959226 10.5001 1.74389 10.0668 2.29989C9.60275 2.89523 8.80342 3.35656 8.03142 3.33256C7.89075 2.56456 8.25275 1.77523 8.69142 1.24256C9.17475 0.655893 10.0014 0.205893 10.7094 0.157227ZM12.6801 8.87788C12.7475 9.84388 13.3441 10.6539 14.2361 10.9912L14.9008 11.2425L14.6088 11.8892C14.2641 12.6519 14.0928 13.0032 13.6501 13.6745C12.9008 14.8165 11.9581 15.9852 10.6508 15.9972C10.1005 16.0009 9.79166 15.8636 9.46925 15.7203C9.15616 15.581 8.83029 15.4361 8.25815 15.4092C7.73231 15.4125 7.40781 15.557 7.08165 15.7022C6.74827 15.8507 6.41315 15.9999 5.85948 15.9999H5.83348C4.49548 15.9879 3.55081 14.8099 2.87681 13.7845C1.17615 11.1965 0.83748 7.93988 2.07148 6.04055C2.89815 4.76722 4.23615 3.97521 5.56281 3.97521C6.24847 3.97521 6.78257 4.16532 7.25406 4.33314L7.25615 4.33388L7.25814 4.33459C7.6173 4.46229 7.9274 4.57255 8.24148 4.57255C8.51748 4.57255 8.76881 4.47788 9.08748 4.35721C9.53881 4.18655 10.1008 3.97455 10.9108 3.97455C12.0795 3.97455 13.2768 4.59721 14.1135 5.63921L14.6115 6.25921L13.9141 6.64122C13.0755 7.10055 12.6141 7.93655 12.6801 8.87788Z" fill="hsla(281, 59%, 38%)" />
    //           </g>
    //           <defs>
    //             <clipPath id="clip0_5619_151">
    //               <rect width="16" height="16" fill="white"/>
    //             </clipPath>
    //           </defs>
    //         </svg>
    //       `,
    //       type: 'GenericNode',
    //       nodeType: 'task:nourishment',
    //       bgColor: 'hsla(281, 93%, 89%)',
    //     },
    //     {
    //       label: 'Run',
    //       labelDescription: 'Add task',
    //       icon: `
    //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    //           <path d="M12 4C13.1046 4 14 3.10457 14 2C14 0.895431 13.1046 0 12 0C10.8954 0 10 0.895431 10 2C10 3.10457 10.8954 4 12 4Z" fill="hsla(29, 62%, 26%)"/>
    //           <path d="M4.48586 10.3735C4.29179 10.198 4.12506 9.99439 3.9912 9.76953L0.394531 14.0855L1.41853 14.9389L4.9392 10.7149C4.71586 10.5535 4.55253 10.4322 4.48586 10.3735Z" fill="hsla(29, 62%, 26%)"/>
    //           <path d="M14.4212 7.15662L12.2925 7.86662L10.2259 4.50995C10.164 4.4051 10.088 4.30931 9.99986 4.22529C9.82771 4.08327 9.62656 3.98064 9.41053 3.92462L5.5332 2.69729C5.3954 2.6539 5.24724 2.65644 5.11101 2.70453C4.97479 2.75262 4.85787 2.84367 4.77786 2.96395L3.0752 5.51795L4.18453 6.25795L5.61053 4.11995L7.70386 4.77929L5.26653 7.48795C5.14862 7.61776 5.0576 7.76964 4.99873 7.93482C4.93986 8.1 4.91431 8.27521 4.92354 8.45033C4.93278 8.62545 4.97662 8.797 5.05254 8.95507C5.12846 9.11314 5.23495 9.25461 5.36586 9.37129C5.6052 9.58195 8.2992 11.4273 8.2992 11.4273L6.1952 13.5286L7.13786 14.4713L9.80453 11.8046C9.87362 11.7355 9.92668 11.6521 9.95995 11.5602C9.99321 11.4683 10.0059 11.3702 9.99703 11.2729C9.98819 11.1756 9.95806 11.0814 9.90878 10.997C9.8595 10.9127 9.79228 10.8401 9.71186 10.7846L7.39986 9.10129L9.83453 6.39995L11.4305 9.01462C11.4902 9.11207 11.5739 9.19256 11.6735 9.24836C11.7732 9.30416 11.8856 9.33341 11.9999 9.33329C12.0714 9.33312 12.1425 9.32165 12.2105 9.29929L14.8432 8.42129L14.4212 7.15662Z" fill="hsla(29, 62%, 26%)"/>
    //         </svg>
    //       `,
    //       nodeType: 'task:run',
    //       type: 'GenericNode',
    //       bgColor: 'hsla(30, 82%, 85%)',
    //     },
    //     {
    //       label: 'Medication',
    //       labelDescription: 'Add task',
    //       icon: `
    //         <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    //           <path d="M14.3337 2.33333C13.467 1.46667 12.267 1 11.067 1C9.86704 1 8.66704 1.46667 7.80037 2.33333L3.06704 7.06667C1.26704 8.86667 1.26704 11.8667 3.06704 13.6667C3.93371 14.5333 5.13371 15 6.33371 15C7.53371 15 8.73371 14.5333 9.60037 13.6667L14.3337 8.93333C16.1337 7.13333 16.1337 4.13333 14.3337 2.33333ZM8.66704 12.7333C8.06704 13.3333 7.20037 13.7333 6.33371 13.7333C5.46704 13.7333 4.60037 13.3333 3.93371 12.7333C2.66704 11.4 2.66704 9.26667 3.93371 8L5.80037 6.13333L10.5337 10.8667L8.66704 12.7333Z" fill="hsla(0, 39%, 40%)" />
    //         </svg>
    //       `,
    //       nodeType: 'task:medication',
    //       type: 'GenericNode',
    //       bgColor: 'hsla(0, 85%, 92%)'
    //     },
    //     {
    //       label: 'Yoga',
    //       labelDescription: 'Add task',
    //       icon: `
    //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    //           <g clipPath="url(#clip0_5651_6312)">
    //             <path d="M8 4C9.10457 4 10 3.10457 10 2C10 0.895431 9.10457 0 8 0C6.89543 0 6 0.895431 6 2C6 3.10457 6.89543 4 8 4Z" fill="hsla(151, 61%, 29%)" />
    //             <path d="M3.33341 11.3335C3.1566 11.3335 2.98703 11.4037 2.86201 11.5288C2.73699 11.6538 2.66675 11.8234 2.66675 12.0002C2.66675 12.177 2.73699 12.3465 2.86201 12.4716C2.98703 12.5966 3.1566 12.6668 3.33341 12.6668H12.6667C12.8436 12.6668 13.0131 12.5966 13.1382 12.4716C13.2632 12.3465 13.3334 12.177 13.3334 12.0002C13.3334 11.8234 13.2632 11.6538 13.1382 11.5288C13.0131 11.4037 12.8436 11.3335 12.6667 11.3335H3.33341Z" fill="hsla(151, 61%, 29%)" />
    //             <path d="M16 14H0V15.3333H16V14Z" fill="hsla(151, 61%, 29%)" />
    //             <path d="M5.99991 6.7825V10.6665H9.99991V6.7825L11.2326 7.83917C11.3505 7.94034 11.5 7.99716 11.6553 7.99984C11.8106 8.00252 11.962 7.95088 12.0832 7.85384L14.2706 6.10384L13.4372 5.0625L11.6812 6.4665L9.95458 4.9865C9.71271 4.77967 9.40483 4.66616 9.08658 4.6665H6.91325C6.59529 4.66685 6.28791 4.78082 6.04658 4.98784L4.31858 6.4665L2.56258 5.0625L1.72925 6.10384L3.91658 7.85384C4.03787 7.95088 4.18925 8.00252 4.34456 7.99984C4.49987 7.99716 4.64937 7.94034 4.76725 7.83917L5.99991 6.7825Z" fill="hsla(151, 61%, 29%)" />
    //           </g>
    //           <defs>
    //             <clipPath id="clip0_5651_6312">
    //               <rect width="16" height="16" fill="white"/>
    //             </clipPath>
    //           </defs>
    //         </svg>
    //       `,
    //       nodeType: 'task:yoga',
    //       type: 'GenericNode',
    //       bgColor: 'hsla(152, 74%, 79%)'
    //     }
    // ]
  },
];
